import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { scrollToElement } from '@rsa-digital/evo-shared-components/helpers/scroll';
import useRetrieveQuote from 'apiHelpers/quote/useRetrieveQuote';
import { AxiosError } from 'axios';
import { isQuoteNotFoundError } from 'businessLogic/errors/isQuoteNotFoundError';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from 'components/Layout';
import RichTextWithModal from 'components/RichTextWithModal';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import RetrieveQuoteReferenceForm from 'forms/RetrieveQuoteReferenceForm';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackAPIError } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import useLoadingState from 'helpers/useLoadingState';
import { DivWithTopMargin, ErrorPanelWithMargin, StyledWelcomeRichText } from './styles';
import { retrieveQuoteErrorPanelId } from '../retrieve-quote';

type RetrieveQuoteReferenceProps = {
  data: {
    csPetRetrieveYourQuote: {
      meta_title: string;
      hero: {
        heading: string;
        subheading: string;
      };
      error_messages: {
        not_found: string;
      };
      welcome_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetRetrieveYourQuote {
      meta_title
      hero {
        heading
        subheading
      }
      error_messages {
        not_found
      }
      welcome_text
    }
  }
`;

const RetrieveQuoteReference: React.FC<RetrieveQuoteReferenceProps> = ({
  data: {
    csPetRetrieveYourQuote: {
      meta_title,
      hero: { heading, subheading },
      error_messages,
      welcome_text,
    },
  },
}) => {
  usePageTracking(meta_title);
  const { retrieveQuoteByInputReferenceAndNavigate } = useRetrieveQuote();
  const { isLoading, withLoadingState } = useLoadingState();
  const [errorPanelText, setErrorPanelText] = useState<string>('');
  const errorHandler = useDefaultErrorHandling();

  return (
    <Layout
      pageTitle={PageTitle.RetrieveQuoteReference}
      heading={heading}
      subheading={subheading}
      metaTitle={meta_title}
      headingGridItemProps={{ desktop: 8, tabletLandscape: 8, tabletPortrait: 6 }}
      subheadingGridItemProps={{ desktop: 8, tabletLandscape: 8, tabletPortrait: 6 }}
      sessionExpiryOption={SessionExpiryOption.NO_SESSION_EXPIRY}>
      <Grid>
        <GridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
          {isLoading && <LoadingOverlay loadingMessage="Retrieving quote, please wait" />}
          <DivWithTopMargin>
            <StyledWelcomeRichText html={welcome_text} />
            {errorPanelText && (
              <FieldGrid alignLeft>
                <ErrorPanelWithMargin id={retrieveQuoteErrorPanelId}>
                  <RichTextWithModal
                    pageTitle={PageTitle.RetrieveQuoteReference}
                    aria-live="assertive"
                    html={errorPanelText}
                  />
                </ErrorPanelWithMargin>
              </FieldGrid>
            )}
            <RetrieveQuoteReferenceForm
              moveNext={async () => {
                try {
                  setErrorPanelText('');
                  await withLoadingState(retrieveQuoteByInputReferenceAndNavigate);
                } catch (err) {
                  const error: AxiosError = err as AxiosError;
                  if (isAxiosError(error) && isQuoteNotFoundError(error)) {
                    trackAPIError(error);
                    setErrorPanelText(error_messages.not_found);
                    scrollToElement(retrieveQuoteErrorPanelId, 20);
                  } else {
                    errorHandler(error);
                  }
                }
              }}
            />
          </DivWithTopMargin>
        </GridItem>
      </Grid>
    </Layout>
  );
};

export default RetrieveQuoteReference;
